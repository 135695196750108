/* eslint-disable complexity */
import { Formik as FormProvider } from 'formik'

import { Logo } from './Field/Logo'
import { PlatformPlan } from './PlatformPlan'

import { Button } from 'components/common/Button'
import {
  CompanyLayout,
  FieldGroup,
  Row,
  TextField,
  FormLayoutProvider,
  SelectField,
  FormAction,
} from 'components/company/common'
import { SUPPLIER_TYPES_DISPLAY_NAMES } from 'constants/supplier'

const CompanyDetail = ({
  isAdmin,
  isInternalEntity = true,
  isInspectingCompany,
  isSubmitting,
  companyDetail = {},
  initialValues,
  validationSchema,
  handleSubmit,
  editing,
  setEditing,
  status,
  organization,
}) => {
  const {
    id,
    name,
    companyName,
    abn,
    website,
    address,
    phone,
    phoneNumber,
    logo,
    commercial,
    billing,
    heroPilot,
    supplierType,
  } = companyDetail

  const pilotServiceOptions = Object.entries(SUPPLIER_TYPES_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  return (
    <FormLayoutProvider value={{ editing, disabled: isSubmitting }}>
      <FormProvider
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, setFieldValue }) => (
          <CompanyLayout
            isAdmin={isAdmin}
            isInternalEntity={isInternalEntity}
            loading={isInspectingCompany}
            status={status}
            type={supplierType}
            company={!isAdmin ? organization.name ?? '' : ''}
            footer={
              editing && (
                <FormAction
                  loading={isSubmitting}
                  okText="Update"
                  loadingText="Updating..."
                  onCancel={() => {
                    setEditing(false)
                    resetForm()
                  }}
                />
              )
            }
          >
            <FieldGroup
              title="Company Details"
              extra={
                !editing ? <Button onClick={() => setEditing(true)}>Edit</Button> : null
              }
            >
              <Row>
                <TextField
                  label="Company Name"
                  value={isAdmin ? name : companyName}
                  name={isAdmin ? 'name' : 'companyName'}
                  required
                />
                <TextField label="ABN" value={abn} name="abn" required />
              </Row>
              <Row>
                <TextField
                  label="Phone"
                  value={isAdmin ? phone : phoneNumber}
                  name={isAdmin ? 'phone' : 'phoneNumber'}
                  required
                />
                <TextField label="Website" value={website} name="website" required />
              </Row>
              <Row>
                <TextField label="Address" value={address} name="address" required />
                {isInternalEntity && (
                  <Logo
                    label="Logo"
                    logo={logo}
                    organizationId={organization ? organization.id : id}
                    name="logo"
                  />
                )}
              </Row>
              {!isInternalEntity && (
                <Row>
                  <SelectField
                    disabled
                    label="Supplier Type"
                    value={SUPPLIER_TYPES_DISPLAY_NAMES[supplierType]}
                    name="supplierType"
                    options={pilotServiceOptions}
                    emptyOption={{ value: undefined, label: '' }}
                    onChange={({ value }) => {
                      setFieldValue('supplierType', value)
                    }}
                    isSearchable={false}
                    required
                    newStyle
                  />
                </Row>
              )}
            </FieldGroup>
            {isInternalEntity ? <PlatformPlan /> : null}
            <FieldGroup title="Commercial Contact">
              <Row>
                <TextField
                  label="First Name"
                  value={commercial?.firstName}
                  name="commercial.firstName"
                  required
                />
                <TextField
                  label="Last Name"
                  value={commercial?.lastName}
                  name="commercial.lastName"
                  required
                />
              </Row>
              <Row>
                <TextField
                  label="Phone"
                  value={commercial?.phoneNumber}
                  name="commercial.phoneNumber"
                  required
                />
                <TextField
                  label="Email"
                  value={commercial?.email}
                  name="commercial.email"
                  disabled
                  required
                />
              </Row>
            </FieldGroup>
            <FieldGroup title="Billing Contact">
              <Row>
                <TextField
                  label="First Name"
                  value={billing?.firstName}
                  name="billing.firstName"
                />
                <TextField
                  label="Last Name"
                  value={billing?.lastName}
                  name="billing.lastName"
                />
              </Row>
              <Row>
                <TextField
                  label="Phone"
                  value={billing?.phoneNumber}
                  name="billing.phoneNumber"
                />
                <TextField label="Email" value={billing?.email} name="billing.email" />
              </Row>
            </FieldGroup>
            <FieldGroup title="Hero Pilot">
              <Row>
                <TextField
                  label="First Name"
                  value={heroPilot?.firstName}
                  name="heroPilot.firstName"
                />
                <TextField
                  label="Last Name"
                  value={heroPilot?.lastName}
                  name="heroPilot.lastName"
                />
              </Row>
              <Row>
                <TextField
                  label="Phone"
                  value={heroPilot?.phoneNumber}
                  name="heroPilot.phoneNumber"
                />
                <TextField
                  label="Email"
                  value={heroPilot?.email}
                  name="heroPilot.email"
                />
              </Row>
            </FieldGroup>
          </CompanyLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { CompanyDetail }
